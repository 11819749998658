import Grid from "@mui/material/Grid2";
import React from "react";
import { useCreateTagMutation, useUpdateTagMutation } from "src/api";
import { ControlledColorPicker } from "src/components/FormFields/ControlledColorPicker";
import { ControlledTextField } from "src/components/FormFields/ControlledTextField";
import { FormEditorDialog } from "src/components/FormFields/FormEditorDialog";
import { emptyTag, validationSchema } from "src/components/Tags/constants";
import { TagType } from "src/components/Tags/types";

export type TagEditorDialogProps = {
    tag?: TagType;
    onClose: () => void;
};

export const TagEditorDialog = ({ tag, onClose }: TagEditorDialogProps) => {
    return (
        <FormEditorDialog
            label="Tag"
            emptyEntity={emptyTag}
            entity={tag}
            onClose={onClose}
            createMutation={useCreateTagMutation}
            updateMutation={useUpdateTagMutation}
            validationSchema={validationSchema}
            formRender={({ control, errors }) => (
                <Grid container spacing={2} size={12}>
                    <Grid size={12}>
                        <ControlledTextField
                            name="name"
                            label="Name"
                            fullWidth
                            control={control}
                            errors={errors}
                        />
                    </Grid>
                    <Grid size={12}>
                        <ControlledTextField
                            name="description"
                            label="Description"
                            fullWidth
                            control={control}
                            errors={errors}
                        />
                    </Grid>
                    <Grid size={12}>
                        <ControlledColorPicker
                            name="color"
                            fullWidth
                            control={control}
                            errors={errors}
                        />
                    </Grid>
                </Grid>
            )}
        />
    );
};
