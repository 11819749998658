export const APP_NAME = "Scarlet";
export const APP_VER = "0.0.1";
export const ENDPOINTS = {
    dev: {
        auth: 'http://localhost:8080',
        api: 'http://localhost:8080'
    },
    prod: {
        auth: 'https://core.naveen.me',
        api: 'https://core.naveen.me'
    }
}

export const AUTH_ENDPOINT = authEndpoint();
export const API_ENDPOINT = apiEndpoint();

export const GLOBAL_DEPARTMENT_ID = 1;

export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_STUDENT = "ROLE_STUDENT";
export const ROLE_FACULTY = "ROLE_FACULTY";

export const ROLES = [
    { id: "STUDENT", label: "Student" },
    { id: "FACULTY", label: "Faculty" },
    { id: "ADMIN", label: "Admin" },
];

export const ROLENAMES = {
    STUDENT: "STUDENT",
    FACULTY: "FACULTY",
    ADMIN: "ADMIN",
};

function apiEndpoint() {
    if (window.location.hostname === 'scarlet.naveen.me') {
        return ENDPOINTS.prod.api;
    }
    return ENDPOINTS.dev.api;
}

function authEndpoint() {
    if (window.location.hostname === 'scarlet.naveen.me') {
        return ENDPOINTS.prod.auth;
    }
    return ENDPOINTS.dev.auth;
}