import * as React from "react";
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

import { Control, Controller, FieldErrors } from "react-hook-form";

export interface ControlledDatePickerProps extends DatePickerProps<any> {
  control?: Control;
  errors: FieldErrors;
}

export const ControlledDatePicker = ({
                                      control,
                                      errors,
                                      ...datePickerProps
                                    }: ControlledDatePickerProps) => (
  <Controller
    name={datePickerProps.name}
    control={control}
    render={({ field }) => (
      <DatePicker
        {...field}
        {...datePickerProps}
      />
    )}
  />
);
