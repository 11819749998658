import { MuiColorInput, MuiColorInputProps } from "mui-color-input";
import * as React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";

export type ControlledColorPickerProps = {
    control?: Control;
    errors: FieldErrors;
} & Omit<MuiColorInputProps, "value">;

export const ControlledColorPicker = ({
    control,
    errors,
    ...muiColorInputProps
}: ControlledColorPickerProps) => (
    <Controller
        name={muiColorInputProps.name}
        control={control}
        render={({ field }) => (
            <MuiColorInput
                {...field}
                format="hex"
                fullWidth
                isAlphaHidden
                error={Boolean(errors[muiColorInputProps.name])}
                helperText={errors[muiColorInputProps.name]?.message.toString()}
            />
        )}
    />
);
