import {
    ErrorOutline as ErrorOutlineIcon,
    Inventory as InventoryIcon,
    LocalMall as LocalMallIcon,
    LocationOn as LocationOnIcon,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import React from "react";
import { useGetProductsQuery, useGetSimpleStatsQuery } from "src/api";

import CountWidget from "./CountWidget";
import SimpleProductTable from "./SimpleProductTable";

const Dashboard = () => {
    const { data: productsResponse, isFetching: productsIsFetching } =
        useGetProductsQuery(`size=5&sort=inventorySize,ASC`);
    const { data: simpleStats, isFetching: statsIsFetching } =
        useGetSimpleStatsQuery();
    return (
        <Grid container spacing={3}>
            <CountWidget
                title="Tasks Without Inventory"
                metricText={simpleStats?.productsWithZeroInventory}
                icon={<ErrorOutlineIcon />}
                metricOutlook="negative"
                color="error"
            />
            <CountWidget
                title="Product Inventories"
                metricText={simpleStats?.totalProductInventories}
                color="success"
                icon={<InventoryIcon />}
            />
            <CountWidget
                title="Tasks"
                metricText={simpleStats?.totalProducts}
                color="primary"
                icon={<LocalMallIcon />}
            />
            <CountWidget
                title="Locations"
                metricText={simpleStats?.totalLocations}
                color="secondary"
                icon={<LocationOnIcon />}
            />
            <Grid item lg={12} md={12} xl={12} xs={12}>
                <SimpleProductTable productsResponse={productsResponse} />
            </Grid>
        </Grid>
    );
};

export default Dashboard;
