import InventoryIcon from "@mui/icons-material/Inventory";
import LabelIcon from "@mui/icons-material/Label";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import TaskIcon from "@mui/icons-material/Task";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { selectNavDrawerOpen } from "./appSlice";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(0),
        }),
    },
}));

const NavDrawer = () => {
    const navDrawerOpen = useSelector(selectNavDrawerOpen);
    return (
        <Drawer variant="permanent" open={navDrawerOpen}>
            <Toolbar />
            <List>
                <ListItem button component={Link} to="/products/inventories">
                    <ListItemIcon>
                        <InventoryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Inventories" />
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to="/products/inventories/checkouts"
                >
                    <ListItemIcon>
                        <PointOfSaleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Checkouts" />
                </ListItem>
                <ListItem button component={Link} to="/products">
                    <ListItemIcon>
                        <LocalMallIcon />
                    </ListItemIcon>
                    <ListItemText primary="Products" />
                </ListItem>
                <ListItem button component={Link} to="/locations">
                    <ListItemIcon>
                        <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary="Locations" />
                </ListItem>
                <ListItem button component={Link} to="/tasks">
                    <ListItemIcon>
                        <TaskIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tasks" />
                </ListItem>
                <ListItem button component={Link} to="/tags">
                    <ListItemIcon>
                        <LabelIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tags" />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default NavDrawer;
