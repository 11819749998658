import * as React from "react";
import { AlertDialog } from "src/components/Shared/AlertDialog";

export const UnsavedChangesAlertDialog = ({ open, setOpen, onClose }) => {
    return (
        <AlertDialog
            open={open}
            onAccept={() => {
                setOpen(false);
                onClose();
            }}
            onDecline={() => {
                setOpen(false);
            }}
            title="You have unsaved changes!"
            content="Are you sure you want to leave ?"
        />
    );
};
