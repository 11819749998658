import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import * as React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { useGetTagsQuery } from "src/api";
import { contrastColor } from "contrast-color";

export type ControlledTagPickerProps = {
  control?: Control;
  errors: FieldErrors;
  name: string;
} & Omit<AutocompleteProps<any, any, any, any>, "renderInput" | "options">;

export const ControlledTagPicker = ({
                                      control,
                                      errors,
                                      name,
                                      ...autoCompleteProps
                                    }: ControlledTagPickerProps) => {
  const { data, isFetching } = useGetTagsQuery("");

  return (<Controller
    name={name}
    control={control}
    render={({ field }) => (
      <Autocomplete
        onChange={(_, value) => field.onChange(value)}
        loading={isFetching}
        multiple
        limitTags={autoCompleteProps.limitTags}
        id={autoCompleteProps.id}
        options={data?.content}
        filterSelectedOptions={true}
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) => option.name}
        value={field.value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <TagChipSelect
              tag={option}
              getTagProps={getTagProps}
              index={index}
            />
          ))}
        renderInput={(params) => (
          <TextField
            {...params} label="Tags" placeholder="Tags"
            error={Boolean(errors[field.name])}
            helperText={errors[field.name]?.message.toString()}
          />)}
      />
    )}
  />);
};

const TagChipSelect = ({ tag, getTagProps, index }) => {
  const labelColor = contrastColor({ bgColor: tag.color });
  const { key, ...tagProps } = getTagProps({ index });
  return (
    <Chip
      key={key}
      variant="outlined"
      style={{
        backgroundColor: tag.color,
        color: labelColor
      }}
      label={tag.name}
      {...tagProps}
    />
  );
};