import { matchIsValidColor } from "mui-color-input";
import { TagType } from "src/components/Tags/types";
import { TaskType } from "src/components/Tasks/types";
import * as yup from "yup";

export const emptyTask: TaskType = {
    name: "",
    note: "",
    tags: [],
    dueBy: undefined,
    completedAt: undefined
};

export const validationSchema = yup.object({
    name: yup.string().label("Name").required().min(3).max(15),
    color: yup
        .string()
        .label("Color")
        .test((val) => {
            if (val) {
                return matchIsValidColor(val);
            }
            return true;
        }),
});
