import EditIcon from "@mui/icons-material/Edit";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetProductInventoriesQuery } from "src/api";
import {
    selectFilter,
    selectSort,
    setFilter,
    setSort,
} from "src/components/DataTable/dataTableSlice";
import {
    numericFilterOperators,
    stringFilterOperators,
} from "src/components/DataTable/utils";
import { ProductInventoryCheckoutDialog } from "src/components/ProductInventoryCheckouts/ProductInventoryCheckoutDialog";
import SeverityPill from "src/components/Shared/SeverityPill";
import ToolbarAddButton from "src/components/Shared/ToolbarAddButton";
import { createQueryString } from "src/utils";

import { ProductInventoryEditorDialog } from "./ProductInventoryEditorDialog";

const CustomToolbar = (toggleProductEditorDialog) => () => {
    return (
        <GridToolbarContainer>
            <ToolbarAddButton action={toggleProductEditorDialog} />
        </GridToolbarContainer>
    );
};

const ProductInventories = () => {
    const sort = useSelector(selectSort("products"));
    const filter = useSelector(selectFilter("products"));
    const [selectedProductInventoryId, setSelectedProductInventoryId] =
        useState();
    const [editorOpen, setEditorOpen] = useState(false);
    const [checkoutOpen, setCheckoutOpen] = useState(false);
    const dispatch = useDispatch();
    const [filterValue, setFilterValue] = useState(filter);

    const closeEditorDialog = () => {
        setEditorOpen(false);
        setSelectedProductInventoryId(undefined);
    };

    const openEditorDialog = (id) => {
        setSelectedProductInventoryId(id);
        setEditorOpen(true);
    };

    const closeCheckoutDialog = () => {
        setCheckoutOpen(false);
        setSelectedProductInventoryId(undefined);
    };

    const openCheckoutDialog = (id) => {
        setSelectedProductInventoryId(id);
        setCheckoutOpen(true);
    };

    const columns = React.useMemo(() => [
        {
            field: "id",
            headerName: "ID",
            width: 120,
            filterOperators: numericFilterOperators,
        },
        {
            field: "product",
            headerName: "Product",
            valueGetter: (params) => params.value.name,
            flex: 0.5,
            filterOperators: stringFilterOperators,
        },
        {
            field: "location",
            headerName: "Location",
            valueGetter: (params) => params.value.name,
            flex: 0.5,
            filterOperators: stringFilterOperators,
        },
        {
            field: "size",
            headerName: "Inventory",
            renderCell: (params) => {
                return (
                    <SeverityPill
                        color={
                            (parseInt(params.value) >= 100 && "success") ||
                            (parseInt(params.value) <= 50 && "error") ||
                            "warning"
                        }
                    >
                        {params.value}
                    </SeverityPill>
                );
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<PointOfSaleIcon />}
                    label="Checkout"
                    onClick={() => openCheckoutDialog(params.id)}
                />,
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => openEditorDialog(params.id)}
                />,
            ],
        },
    ]);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setFilter({ tableId: "products", filter: filterValue }));
        }, 1000);

        return () => clearTimeout(timer);
    }, [filterValue]);

    const queryString = React.useMemo(
        () => createQueryString(sort, filter),
        [sort, filter],
    );
    const { data, isFetching } = useGetProductInventoriesQuery(queryString);

    let selectedProductInventory = undefined;

    if (data && selectedProductInventoryId) {
        const productInventories = data.content;
        selectedProductInventory = productInventories.find(
            (element) => element.id === selectedProductInventoryId,
        );
    }

    return (
        <React.Fragment>
            <ProductInventoryEditorDialog
                open={editorOpen}
                onClose={closeEditorDialog}
                productInventory={selectedProductInventory}
            />
            <ProductInventoryCheckoutDialog
                open={checkoutOpen}
                onClose={closeCheckoutDialog}
                productInventory={selectedProductInventory}
            />
            <DataGrid
                filterModel={filterValue}
                sortModel={sort}
                rows={data?.content || []}
                columns={columns}
                pageSize={data?.number || 0}
                sortingMode="server"
                onSortModelChange={(params) => {
                    dispatch(setSort({ tableId: "products", sort: params }));
                }}
                filterMode="server"
                onFilterModelChange={setFilterValue}
                loading={isFetching}
                autoPageSize
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            createdAt: false,
                        },
                    },
                }}
                slots={{
                    toolbar: CustomToolbar(openEditorDialog),
                }}
            />
        </React.Fragment>
    );
};

export default ProductInventories;
