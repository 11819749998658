import Grid from "@mui/material/Grid2";
import React from "react";
import { useCreateTaskMutation, useUpdateTaskMutation } from "src/api";
import { ControlledColorPicker } from "src/components/FormFields/ControlledColorPicker";
import { ControlledTagPicker } from "src/components/FormFields/ControlledTagPicker";
import { ControlledTextField } from "src/components/FormFields/ControlledTextField";
import { ControlledDatePicker } from "src/components/FormFields/ControlledDatePicker";
import { FormEditorDialog } from "src/components/FormFields/FormEditorDialog";
import { emptyTask, validationSchema } from "src/components/Tasks/constants";
import { TaskType } from "src/components/Tasks/types";

export type TaskEditorDialogProps = {
    task?: TaskType;
    onClose: () => void;
};

export const TaskEditorDialog = ({ task, onClose }: TaskEditorDialogProps) => {
    return (
        <FormEditorDialog
            label="Task"
            emptyEntity={emptyTask}
            entity={task}
            onClose={onClose}
            createMutation={useCreateTaskMutation}
            updateMutation={useUpdateTaskMutation}
            validationSchema={validationSchema}
            formRender={({ control, errors }) => (
                <Grid container spacing={2} size={12}>
                    <Grid size={12}>
                        <ControlledTextField
                            name="name"
                            label="Name"
                            fullWidth
                            control={control}
                            errors={errors}
                        />
                    </Grid>
                    <Grid size={12}>
                        <ControlledTextField
                            name="note"
                            label="Note"
                            fullWidth
                            control={control}
                            errors={errors}
                        />
                    </Grid>
                    <Grid container size={12} spacing={2}>
                        <ControlledDatePicker
                            name="dueBy"
                            label="Due By"
                            control={control}
                            errors={errors}
                        />
                      <ControlledDatePicker
                        name="completedAt"
                        label="Completed At"
                        control={control}
                        errors={errors}
                      />
                    </Grid>
                  <Grid size={12}>
                      <ControlledTagPicker
                        errors={errors}
                        control={control}
                        name="tags"
                      />
                  </Grid>
                </Grid>
            )}
        />
    );
};
