import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useState } from "react";
import { useGetUserDetailsQuery } from "src/api";
import AccountForm from "src/components/Account/AccountForm";
import PasswordForm from "src/components/Account/PasswordForm";
import { useAuth } from "src/hooks/useAuth";

const Account = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const auth = useAuth();
    const { data: userDetails } = useGetUserDetailsQuery(auth?.userId);

    if (userDetails) {
        return (
            <Paper elevation={0}>
                <Box>
                    <Tabs
                        value={selectedTab}
                        onChange={(event, tabId) => setSelectedTab(tabId)}
                    >
                        <Tab sx={{ textTransform: "none" }} label="General" />
                        <Tab sx={{ textTransform: "none" }} label="Security" />
                    </Tabs>
                </Box>
                <div>
                    {(() => {
                        switch (selectedTab) {
                            case 0:
                                return (
                                    <AccountForm
                                        firstName={userDetails.firstName}
                                        lastName={userDetails.lastName}
                                        email={userDetails.email}
                                        phone={userDetails.phone}
                                    />
                                );

                            case 1:
                                return <PasswordForm />;

                            default:
                                return null;
                        }
                    })()}
                </div>
            </Paper>
        );
    }

    return null;
};

export default Account;
