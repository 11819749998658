import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { withFormik } from "formik";
import React from "react";
import * as yup from "yup";

const PasswordForm = ({
    values,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    isSubmitting,
}) => (
    <Paper>
        <Box
            component="form"
            onSubmit={handleSubmit}
            onChange={handleChange}
            m={2}
            p={2}
        >
            <fieldset sx={{ padding: 0, margin: 0 }} disabled={isSubmitting}>
                <Grid container rowSpacing={3}>
                    <Grid item xs={12} md={12}>
                        <TextField
                            required
                            id="oldPassword"
                            label="Old Password"
                            type="password"
                            value={values.oldPassword}
                            error={Boolean(errors.oldPassword)}
                            helperText={errors.oldPassword}
                            autoComplete="current-password"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            required
                            id="newPassword"
                            label="New Password"
                            type="password"
                            value={values.newPassword}
                            error={Boolean(errors.newPassword)}
                            helperText={errors.newPassword}
                            autoComplete="new-password"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            id="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            value={values.confirmPassword}
                            error={Boolean(errors.confirmPassword)}
                            helperText={errors.confirmPassword}
                            autoComplete="new-password"
                            fullWidth
                        />
                    </Grid>
                    <Grid container item sm={12} justifyContent="flex-end">
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting || !isValid}
                        >
                            Save Changes
                        </Button>
                    </Grid>
                </Grid>
            </fieldset>
        </Box>
    </Paper>
);

const validationSchema = yup.object({
    oldPassword: yup.string().required("Old Password is required"),
    newPassword: yup
        .string()
        .required("New Password is required")
        .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase, one number and one special case character",
        ),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

export default withFormik({
    mapPropsToValues: () => ({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    }),
    validationSchema: validationSchema,
    validateOnMount: true,
    handleSubmit: (values, { setSubmitting }) => {
        setSubmitting(true);
        console.log(values);
    },
})(PasswordForm);

// TODO: Show error message only on touch
