import Chip from "@mui/material/Chip";
import { contrastColor } from "contrast-color";
import { TagType } from "src/components/Tags/types";

export type TagChipProps = {
    tag: TagType;
};

export const TagChip = ({ tag }: TagChipProps) => {
    const labelColor = contrastColor({ bgColor: tag.color });
    return (
        <Chip
            key={tag.id}
            style={{
                backgroundColor: tag.color,
                color: labelColor,
            }}
            label={tag.name}
        />
    );
};
