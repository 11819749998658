import { matchIsValidColor } from "mui-color-input";
import { TagType } from "src/components/Tags/types";
import * as yup from "yup";

export const emptyTag: TagType = {
    name: "",
    description: "",
    color: "",
};

export const validationSchema = yup.object({
    name: yup.string().label("Name").required().min(3).max(15),
    color: yup
        .string()
        .label("Color")
        .test((val) => {
            if (val) {
                return matchIsValidColor(val);
            }
            return true;
        }),
});
