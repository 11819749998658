import EditIcon from "@mui/icons-material/Edit";
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetLocationsQuery } from "src/api";
import {
    selectFilter,
    selectSort,
    setFilter,
    setSort,
} from "src/components/DataTable/dataTableSlice";
import {
    numericFilterOperators,
    stringFilterOperators,
} from "src/components/DataTable/utils";
import ToolbarAddButton from "src/components/Shared/ToolbarAddButton";
import { createQueryString } from "src/utils";

import { LocationEditorDialog } from "./LocationEditorDialog";

const CustomToolbar = (toggleLocationEditorDialog) => () => {
    return (
        <GridToolbarContainer>
            <ToolbarAddButton action={toggleLocationEditorDialog} />
        </GridToolbarContainer>
    );
};

const Locations = () => {
    const sort = useSelector(selectSort("locations"));
    const filter = useSelector(selectFilter("locations"));
    const [selectedLocationId, setSelectedLocationId] = useState();
    const [editorOpen, setEditorOpen] = useState(false);
    const dispatch = useDispatch();
    const [filterValue, setFilterValue] = useState(filter);

    const closeEditorDialog = () => {
        setEditorOpen(false);
        setSelectedLocationId(undefined);
    };

    const openEditorDialog = (id) => {
        setSelectedLocationId(id);
        setEditorOpen(true);
    };

    const columns = React.useMemo(() => [
        {
            field: "id",
            headerName: "ID",
            width: 120,
            filterOperators: numericFilterOperators,
        },
        {
            field: "name",
            headerName: "Name",
            width: 250,
            filterOperators: stringFilterOperators,
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
            filterOperators: stringFilterOperators,
        },
        { field: "createdAt", headerName: "Created At" },
        {
            field: "actions",
            type: "actions",
            headerName: "",
            width: 10,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => openEditorDialog(params.id)}
                    showInMenu
                />,
            ],
        },
    ]);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setFilter({ tableId: "locations", filter: filterValue }));
        }, 1000);

        return () => clearTimeout(timer);
    }, [filterValue]);

    const queryString = React.useMemo(
        () => createQueryString(sort, filter),
        [sort, filter],
    );
    const { data, isFetching } = useGetLocationsQuery(queryString);

    let selectedLocation = undefined;

    if (data && setSelectedLocationId) {
        const locations = data.content;
        selectedLocation = locations.find(
            (element) => element.id === selectedLocationId,
        );
    }

    return (
        <React.Fragment>
            <LocationEditorDialog
                open={editorOpen}
                onClose={closeEditorDialog}
                location={selectedLocation}
            />
            <DataGrid
                filterModel={filterValue}
                sortModel={sort}
                rows={data?.content || []}
                columns={columns}
                pageSize={data?.number || 0}
                sortingMode="server"
                onSortModelChange={(params) => {
                    dispatch(setSort({ tableId: "locations", sort: params }));
                }}
                filterMode="server"
                onFilterModelChange={setFilterValue}
                loading={isFetching}
                autoPageSize
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            createdAt: false,
                        },
                    },
                }}
                slots={{
                    toolbar: CustomToolbar(openEditorDialog),
                }}
            />
        </React.Fragment>
    );
};

export default Locations;
