import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

export const AlertDialog = ({
    open,
    title,
    content,
    onAccept,
    onDecline,
    acceptLabel = "Yes",
    declineLabel = "No",
}) => {
    return (
        <React.Fragment>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                aria-modal
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDecline} autoFocus>
                        {declineLabel}
                    </Button>
                    <Button onClick={onAccept}>{acceptLabel}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};
