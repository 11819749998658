import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Account from "src/components/Account/Account";
import Authenticated from "src/components/Auth/Authenticated";
import AuthorizedRoute from "src/components/Auth/AuthorizedRoute";
import Container from "src/components/Container";
import Dashboard from "src/components/Dashboard/Dashboard";
import Locations from "src/components/Locations/Locations";
import ProductInventories from "src/components/ProductInventories/ProductInventories";
import ProductInventoryCheckouts from "src/components/ProductInventoryCheckouts/ProductInventoryCheckouts";
import Products from "src/components/Products/Products";
import RouteNotFound from "src/components/Shared/RouteNotFound";
import Tags from "src/components/Tags/Tags";
import Tasks from "src/components/Tasks/Tasks";

// 3️⃣ Router singleton created
const router = createBrowserRouter([
    { path: "/authorized", Component: Authenticated },
    {
        path: "/*",
        element: (
            <AuthorizedRoute>
                <Container />
            </AuthorizedRoute>
        ),
        children: [
            {
                path: "account",
                element: <Account />,
            },
            {
                path: "products",
                element: <Products />,
            },
            {
                path: "products/:productId/inventories",
                element: <ProductInventories />,
            },
            {
                path: "products/inventories",
                element: <ProductInventories />,
            },
            {
                path: "products/inventories/checkouts",
                element: <ProductInventoryCheckouts />,
            },
            {
                path: "locations",
                element: <Locations />,
            },
            {
                path: "tags",
                element: <Tags />,
            },
            {
                path: "tasks",
                element: <Tasks />,
            },
            {
                path: "",
                element: <Dashboard />,
            },
        ],
    },
    { path: "*", Component: RouteNotFound },
]);

// 4️⃣ RouterProvider added
export default function App() {
    return <RouterProvider router={router} />;
}
