import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { JwtPayload, jwtDecode } from "jwt-decode";

import type { RootState } from "../../store";

export interface AuthState extends JwtPayload {
    token?: string;
    userId?: number;
    role?: string;
}

const initialState: AuthState = {};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<any>) => {
            const { access_token } = action.payload;
            const decodedToken = jwtDecode<AuthState>(access_token);
            state.token = access_token;
            state.userId = decodedToken.userId;
            state.role = decodedToken.role;
        },
        clearCredentials: (state) => {
            state.token = undefined;
            state.userId = undefined;
            state.role = undefined;
        },
    },
});

export const { setCredentials, clearCredentials } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.userId;
