import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid2";
import { TypedUseMutation } from "@reduxjs/toolkit/query/react";
import React, { ReactElement, useEffect } from "react";
import { Control, FieldErrorsImpl, useForm } from "react-hook-form";
import { UnsavedChangesAlertDialog } from "src/components/FormFields/UnsavedChangesAlertDialog";
import { UpdateNotification } from "src/components/FormFields/UpdateNotification";
import { ApiErrorType } from "src/types";
import { isApiError, isFetchBaseQueryError, mergeFormData } from "src/utils";
import * as yup from "yup";

export type FormEditorDialogRenderProps = {
    control: Control;
    errors: FieldErrorsImpl;
};

export type FormEditorDialogProps<T> = {
    label: string;
    emptyEntity: T;
    entity?: T;
    createMutation: TypedUseMutation<any, any, any>;
    updateMutation: TypedUseMutation<any, any, any>;
    validationSchema: yup.ObjectSchema<T>;
    formRender: (props: FormEditorDialogRenderProps) => ReactElement;
    onClose: () => void;
};

export const FormEditorDialog = ({
    label,
    emptyEntity,
    entity,
    onClose,
    createMutation,
    updateMutation,
    validationSchema,
    formRender,
}: FormEditorDialogProps<any>) => {
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [createEntity, createResult] = createMutation();
    const [updateEntity, updateResult] = updateMutation();

    const {
        control,
        handleSubmit,
        reset,
        setError,
        formState: { defaultValues, errors, isSubmitted, isDirty },
    } = useForm({
        defaultValues: mergeFormData(emptyEntity, entity, false),
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = handleSubmit(async (data) => {
        try {
            if (!data.id) {
                createEntity(data);
            } else {
                updateEntity(data);
            }
        } catch (error) {
            console.error(error);
        }
    });

    const isEdit = Boolean(defaultValues.id);
    const result = isEdit ? updateResult : createResult;
    const { isLoading } = result;

    useEffect(() => {
        if (result.isSuccess) {
            reset(mergeFormData(emptyEntity, result.data, false));
        } else if (result.isError && isFetchBaseQueryError(result.error)) {
            if (isApiError(result.error.data)) {
                const error: ApiErrorType = result.error.data;
                setError("root.serverError", {
                    type: error.status,
                    message: error.message,
                });
                error.errors.forEach((error) => {
                    setError(error.field, {
                        type: "manual",
                        message: error.message,
                    });
                });
            }
        }
    }, [result, reset, emptyEntity, setError]);

    const onCancel = isDirty ? () => setAlertOpen(true) : onClose;

    return (
        <React.Fragment>
            <Dialog open={true} fullWidth maxWidth={"md"} aria-modal>
                <DialogTitle>{`${isEdit ? "Edit" : "Add"} ${label} ${isEdit ? defaultValues.id : ""}`}</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 0.5 }} component="form" onSubmit={onSubmit}>
                        <fieldset disabled={isLoading}>
                            <Grid container spacing={2} size={12}>
                                {formRender({ control, errors })}
                                <Grid container spacing={2} size={12}>
                                    <Grid size={{ xs: 12, sm: 6 }}>
                                        <UpdateNotification
                                            label={label}
                                            updateResult={updateResult}
                                            createResult={createResult}
                                            isSubmitted={isSubmitted}
                                            isDirty={isDirty}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        size={{ xs: 12, sm: 6 }}
                                        justifyContent="flex-end"
                                    >
                                        <Button
                                            variant="contained"
                                            onClick={onCancel}
                                            size="large"
                                            disabled={isLoading}
                                            sx={{ mr: 2 }}
                                        >
                                            {isDirty ? "Cancel" : "Close"}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            size="large"
                                            disabled={isLoading || !isDirty}
                                        >
                                            Save Changes
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </fieldset>
                    </Box>
                </DialogContent>
            </Dialog>
            <UnsavedChangesAlertDialog
                open={alertOpen}
                setOpen={setAlertOpen}
                onClose={onClose}
            />
        </React.Fragment>
    );
};
