import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";

function RouteNotFound() {
    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item>
                <Stack>
                    <Typography variant="h1" component="div" gutterBottom>
                        404
                    </Typography>
                    <Typography variant="h4" component="div" gutterBottom>
                        The page you are looking for is not found.
                    </Typography>
                    <Typography variant="h6" component="div" gutterBottom>
                        Please go elsewhere...
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default RouteNotFound;
