import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { api } from "./api";
import authReducer from "./components/Auth/authSlice";
import { dataTableSlice } from "./components/DataTable/dataTableSlice";
import appReducer from "./components/Shared/appSlice";

export const store = configureStore({
    reducer: {
        app: appReducer,
        [api.reducerPath]: api.reducer,
        auth: authReducer,
        [dataTableSlice.name]: dataTableSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(api.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch;
