import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";

export type ControlledTextFieldProps = {
    control?: Control;
    errors: FieldErrors;
} & Omit<TextFieldProps, "variant">;

export const ControlledTextField = ({
    control,
    errors,
    ...textFieldProps
}: ControlledTextFieldProps) => (
    <Controller
        name={textFieldProps.name}
        control={control}
        render={({ field }) => (
            <TextField
                {...field}
                {...textFieldProps}
                error={Boolean(errors[textFieldProps.name])}
                helperText={errors[textFieldProps.name]?.message.toString()}
            />
        )}
    />
);
