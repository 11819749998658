import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const ToolbarAddButton = ({ action }) => (
    <Tooltip title="Add">
        <IconButton onClick={action}>
            <AddIcon />
        </IconButton>
    </Tooltip>
);

export default ToolbarAddButton;
