import { Alert } from "@mui/material";
import * as React from "react";

export const UpdateNotification = ({
    label,
    createResult,
    updateResult,
    isSubmitted,
    isDirty,
}) => {
    if (isSubmitted && !isDirty) {
        return;
    }

    if (createResult.isLoading || updateResult.isLoading) {
        return;
    }

    let isError = false;
    let message = undefined;

    // Update path
    if (updateResult.isError || updateResult.isSuccess) {
        isError = updateResult.isError;
        message = `${label} updated successfully.`;
        if (isError) {
            message = `${label} update failed.`;
        }
    } else if (createResult.isError || createResult.isSuccess) {
        isError = createResult.isError;
        message = `${label} created successfully.`;
        if (isError) {
            message = `${label} create failed.`;
        }
    }

    if (message) {
        return (
            <Alert variant="standard" severity={isError ? "error" : "success"}>
                {message}
            </Alert>
        );
    }
};
