import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
} from "@mui/material";

const CountWidget = ({
    title,
    metricText,
    trendPercent,
    trendText,
    color,
    icon,
}) => {
    return (
        <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Card sx={{ height: "100%" }}>
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                        sx={{ justifyContent: "space-between" }}
                    >
                        <Grid item>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="overline"
                            >
                                {title}
                            </Typography>
                            <Typography color="textPrimary" variant="h4">
                                {metricText}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: `${color}.main`,
                                    height: 56,
                                    width: 56,
                                }}
                            >
                                <div>{icon}</div>
                            </Avatar>
                        </Grid>
                    </Grid>
                    {trendPercent && (
                        <Box
                            sx={{
                                pt: 2,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <ArrowDownwardIcon color={color} />
                            <Typography
                                color={color}
                                sx={{
                                    mr: 1,
                                }}
                                variant="body2"
                            >
                                {trendPercent}
                            </Typography>
                            <Typography color="textSecondary" variant="caption">
                                {trendText}
                            </Typography>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Grid>
    );
};

export default CountWidget;
