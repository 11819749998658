import EditIcon from "@mui/icons-material/Edit";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridToolbarContainer,
} from "@mui/x-data-grid";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetTagsQuery } from "src/api";
import {
    selectFilter,
    selectSort,
    setFilter,
    setSort,
} from "src/components/DataTable/dataTableSlice";
import {
    numericFilterOperators,
    stringFilterOperators,
} from "src/components/DataTable/utils";
import ToolbarAddButton from "src/components/Shared/ToolbarAddButton";
import { TagChip } from "src/components/Tags/TagChip";
import { TagEditorDialog } from "src/components/Tags/TagEditorDialog";
import { TagType } from "src/components/Tags/types";
import { createQueryString } from "src/utils";

const CustomToolbar =
    (toggleTagEditorDialog: (e: SyntheticEvent, id: number) => void) => () => {
        return (
            <GridToolbarContainer>
                <ToolbarAddButton action={toggleTagEditorDialog} />
            </GridToolbarContainer>
        );
    };

const Tags = () => {
    const sort = useSelector(selectSort("tags"));
    const filter = useSelector(selectFilter("tags"));
    const [selectedTagId, setSelectedTagId] = useState<number>();
    const [editorOpen, setEditorOpen] = useState<Boolean>(false);
    const dispatch = useDispatch();
    const [filterValue, setFilterValue] = useState(filter);

    const closeEditorDialog = () => {
        setEditorOpen(false);
        setSelectedTagId(undefined);
    };

    const openEditorDialog = (e: SyntheticEvent, id: number) => {
        if (id) {
            setSelectedTagId(id);
        }
        setEditorOpen(true);
    };

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                field: "id",
                headerName: "ID",
                width: 120,
                filterOperators: numericFilterOperators,
            },
            {
                field: "name",
                headerName: "Name",
                flex: 1,
                filterOperators: stringFilterOperators,
                renderCell: (p: { row: any }) => {
                    return <TagChip tag={p.row} />;
                },
            },
            {
                field: "description",
                headerName: "Description",
                flex: 3,
                filterOperators: stringFilterOperators,
            },
            {
                field: "color",
                headerName: "Color",
                width: 250,
                filterOperators: stringFilterOperators,
            },
            { field: "createdAt", headerName: "Created At" },
            {
                field: "actions",
                type: "actions",
                width: 10,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={(e) => openEditorDialog(e, Number(params.id))}
                        showInMenu
                    />,
                ],
            },
        ],
        [],
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setFilter({ tableId: "tags", filter: filterValue }));
        }, 1000);

        return () => clearTimeout(timer);
    }, [filterValue, dispatch]);

    const queryString = React.useMemo(
        () => createQueryString(sort, filter),
        [sort, filter],
    );
    const { data, isFetching } = useGetTagsQuery(queryString);

    let selectedTag: TagType;

    if (data && selectedTagId) {
        const tags: TagType[] = data.content;
        selectedTag = tags.find((element) => element.id === selectedTagId);
    }

    return (
        <React.Fragment>
            {editorOpen && (
                <TagEditorDialog
                    onClose={closeEditorDialog}
                    tag={selectedTag}
                />
            )}
            <DataGrid
                filterModel={filterValue}
                sortModel={sort}
                rows={data?.content || []}
                columns={columns}
                pageSizeOptions={[data?.number || 0]}
                sortingMode="server"
                onSortModelChange={(params) => {
                    dispatch(setSort({ tableId: "tags", sort: params }));
                }}
                filterMode="server"
                onFilterModelChange={setFilterValue}
                loading={isFetching}
                autoPageSize
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            createdAt: false,
                            id: false,
                        },
                    },
                }}
                slots={{
                    toolbar: CustomToolbar(openEditorDialog),
                }}
            />
        </React.Fragment>
    );
};

export default Tags;
