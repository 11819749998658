import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from "@mui/material";
import { useFormik } from "formik";
import {
    useCreateProductInventoryMutation,
    useLazyGetLocationsQuery,
    useLazyGetProductsQuery,
    useUpdateProductInventoryMutation,
} from "src/api";
import Autocomplete from "src/components/Shared/AutoComplete";
import * as yup from "yup";

export const ProductInventoryEditorDialog = ({
    productInventory,
    open,
    onClose,
}) => {
    const [createProductInventory, createResult] =
        useCreateProductInventoryMutation();
    const [updateProductInventory, updateResult] =
        useUpdateProductInventoryMutation();

    const [getProducts] = useLazyGetProductsQuery();
    const [getLocations] = useLazyGetLocationsQuery();

    const isLoading = createResult.isLoading || updateResult.isLoading;

    const isEdit = Boolean(productInventory);
    const dialogTitle = isEdit ? "Edit" : "Add";

    const {
        values,
        touched,
        errors,
        isValid,
        setFieldValue,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: {
            id: productInventory?.id || "",
            product: productInventory?.product || null,
            location: productInventory?.location || null,
            size: productInventory?.size || 0,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (values.id) {
                updateProductInventory(values);
            } else {
                createProductInventory({
                    ...values,
                    productId: values.product.id,
                    locationId: values.location.id,
                });
            }
            onClose();
            resetForm();
        },
    });

    return (
        <Dialog open={open} fullWidth maxWidth={"md"}>
            <DialogTitle>{`${dialogTitle} Product Inventory`}</DialogTitle>
            <DialogContent>
                <Box
                    sx={{ mt: 1 }}
                    component="form"
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                >
                    <fieldset disabled={isLoading}>
                        <Grid container rowSpacing={3}>
                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                    id="product"
                                    label="Product"
                                    queryItems={getProducts}
                                    error={
                                        touched.product &&
                                        Boolean(errors.product)
                                    }
                                    helperText={touched.size && errors.product}
                                    onChange={setFieldValue}
                                    disabled={isEdit}
                                    value={values?.product}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                    id="location"
                                    label="Location"
                                    queryItems={getLocations}
                                    error={
                                        touched.location &&
                                        Boolean(errors.location)
                                    }
                                    helperText={touched.size && errors.location}
                                    onChange={setFieldValue}
                                    disabled={isEdit}
                                    value={values?.location}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="size"
                                    label="Size"
                                    type="number"
                                    value={values?.size}
                                    error={touched.size && Boolean(errors.size)}
                                    helperText={touched.size && errors.size}
                                    fullWidth
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                sm={12}
                                justifyContent="flex-end"
                            >
                                <Button
                                    variant="secondary"
                                    onClick={onClose}
                                    disabled={isLoading}
                                    sx={{ mr: 2 }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={isLoading || !isValid}
                                >
                                    Save Changes
                                </Button>
                            </Grid>
                        </Grid>
                    </fieldset>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

const validationSchema = yup.object({
    location: yup.object().nullable().required("Location is required"),
    product: yup.object().nullable().required("Product is required"),
    size: yup.number().required("Size is required"),
});
