import { Box, Toolbar } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import { Outlet } from "react-router-dom";
import AppBar from "src/components/AppBar/AppBar";
import Breadcrumbs from "src/components/Shared/Breadcrumbs";
import NavDrawer from "src/components/Shared/NavDrawer";

const Container = () => {
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar />
            <NavDrawer />
            <Box
                component="main"
                sx={{
                    display: "flex",
                    flexGrow: 1,
                    height: "100vh",
                    flexDirection: "column",
                }}
            >
                <Toolbar />
                <Breadcrumbs />
                <Box sx={{ flexGrow: 1, ml: 2, mb: 2, mr: 2 }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
};

export default Container;
