import AccountCircle from "@mui/icons-material/AccountCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearCredentials } from "src/components/Auth/authSlice";
import {
    selectNavDrawerOpen,
    toggleNavDrawer,
} from "src/components/Shared/appSlice";
import { API_ENDPOINT, APP_NAME, AUTH_ENDPOINT } from "src/constants";

const _AppBar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navDrawerOpen = useSelector(selectNavDrawerOpen);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar
            position="fixed"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    sx={{
                        marginRight: "36px",
                    }}
                    onClick={() => dispatch(toggleNavDrawer())}
                >
                    {navDrawerOpen ? <MenuIcon /> : <ChevronLeftIcon />}
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                    {APP_NAME}
                </Typography>
                <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    sx={{ flexGrow: 1, mt: -1, pt: -1 }}
                >
                    αlpha
                </Typography>
                <div>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        sx={{ width: 320, maxWidth: "100%" }}
                    >
                        <MenuItem
                            component={Link}
                            to="/account"
                            onClick={handleClose}
                        >
                            <ListItemIcon>
                                <SettingsIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Account</ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                window.location.href = `${AUTH_ENDPOINT}/logout`;
                            }}
                        >
                            <ListItemIcon>
                                <LogoutIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Logout</ListItemText>
                        </MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default _AppBar;
