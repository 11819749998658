import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React from "react";
import { useCreateProductMutation, useUpdateProductMutation } from "src/api";
import * as yup from "yup";

const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
});

export const ProductEditorDialog = ({ open, product, onClose }) => {
    const [createProduct, createResult] = useCreateProductMutation();
    const [updateProduct, updateResult] = useUpdateProductMutation();

    const isLoading = createResult.isLoading || updateResult.isLoading;

    const formik = useFormik({
        initialValues: {
            id: product?.id || "",
            name: product?.name || "",
            description: product?.description || "",
            externalId: product?.externalId || "",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (values.id) {
                updateProduct(values);
            } else {
                createProduct(values);
            }
            onClose();
            resetForm();
        },
    });

    const { handleSubmit, handleChange, values, isValid, errors } = formik;

    return (
        <Dialog open={open} fullWidth maxWidth={"md"}>
            <DialogTitle>Add Product</DialogTitle>
            <DialogContent>
                <Box
                    sx={{ mt: 1 }}
                    component="form"
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                >
                    <fieldset disabled={isLoading}>
                        <Grid container rowSpacing={3}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    required
                                    id="name"
                                    label="Name"
                                    value={values.name}
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="description"
                                    label="Description"
                                    value={values.description}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="externalId"
                                    label="External Id"
                                    value={values.externalId}
                                    fullWidth
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                sm={12}
                                justifyContent="flex-end"
                            >
                                <Button
                                    variant="secondary"
                                    onClick={onClose}
                                    disabled={isLoading}
                                    sx={{ mr: 2 }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={isLoading || !isValid}
                                >
                                    Save Changes
                                </Button>
                            </Grid>
                        </Grid>
                    </fieldset>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
