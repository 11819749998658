import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
// Grid version 2
import { useLocation, useNavigate } from "react-router-dom";
import { useGetTokenQuery } from "src/api";

import { setCredentials } from "./authSlice";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Authenticated() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let query = useQuery();

    const { data, isSuccess } = useGetTokenQuery(query.get("code"));

    useEffect(() => {
        if (isSuccess) {
            dispatch(setCredentials(data));
            navigate("/");
        }
    }, [data, dispatch, isSuccess, navigate]);

    return (
        <Grid container spacing={2} height={"100%"}>
            <Grid xs={12} align="center">
                <CircularProgress />
            </Grid>
            <Grid xs={12} align="center">
                Please wait ...
            </Grid>
        </Grid>
    );
}

export default Authenticated;
