import {
    getGridNumericOperators,
    getGridStringOperators,
} from "@mui/x-data-grid";

export const numericFilterOperators = getGridNumericOperators().filter(
    (operator) => operator.value === "=",
);

export const stringFilterOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains",
);
