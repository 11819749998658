import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "src/store";

export interface AppState {
    navDrawerOpen: boolean;
}

const initialState: AppState = {
    navDrawerOpen: true,
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        toggleNavDrawer: (state) => {
            state.navDrawerOpen = !state.navDrawerOpen;
        },
    },
});

export const { toggleNavDrawer } = appSlice.actions;

export default appSlice.reducer;

export const selectNavDrawerOpen = (state: RootState) =>
    state.app.navDrawerOpen;
