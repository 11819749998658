import EditIcon from "@mui/icons-material/Edit";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef, GridRenderCellParams,
    GridToolbarContainer
} from "@mui/x-data-grid";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetTasksQuery } from "src/api";
import {
    selectFilter,
    selectSort,
    setFilter,
    setSort,
} from "src/components/DataTable/dataTableSlice";
import {
    numericFilterOperators,
    stringFilterOperators,
} from "src/components/DataTable/utils";
import ToolbarAddButton from "src/components/Shared/ToolbarAddButton";
import { TagChip } from "src/components/Tags/TagChip";
import { TagType } from "src/components/Tags/types";
import { TaskEditorDialog } from "src/components/Tasks/TaskEditorDialog";
import { TaskType } from "src/components/Tasks/types";
import { createQueryString } from "src/utils";

const CustomToolbar =
    (toggleTaskEditorDialog: (e: SyntheticEvent, id: string) => void) => () => {
        return (
            <GridToolbarContainer>
                <ToolbarAddButton action={toggleTaskEditorDialog} />
            </GridToolbarContainer>
        );
    };

const Tasks = () => {
    const sort = useSelector(selectSort("tasks"));
    const filter = useSelector(selectFilter("tasks"));
    const [selectedTaskId, setSelectedTaskId] = useState<string>();
    const [editorOpen, setEditorOpen] = useState<Boolean>(false);
    const dispatch = useDispatch();
    const [filterValue, setFilterValue] = useState(filter);

    const closeEditorDialog = () => {
        setEditorOpen(false);
        setSelectedTaskId(undefined);
    };

    const openEditorDialog = (e: SyntheticEvent, id: string) => {
        if (id) {
            setSelectedTaskId(id);
        }
        setEditorOpen(true);
    };

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                field: "id",
                headerName: "ID",
                width: 120,
                filterOperators: numericFilterOperators,
            },
            {
                field: "name",
                headerName: "Name",
                width: 200,
                filterOperators: stringFilterOperators,
            },
            {
                field: "note",
                headerName: "Note",
                flex: 1,
                filterOperators: stringFilterOperators,
            },
            {
                field: "complete",
                headerName: "Complete",
                width: 80,
                filterOperators: stringFilterOperators,
            },
            { field: "dueBy", headerName: "Due By", width: 150, },
            { field: "tags", headerName: "Tags", width: 250,
                renderCell: (p: GridRenderCellParams<any, TagType[]>) => (
                    <React.Fragment>
                        {p.value?.map(val => <TagChip tag={val} />)}
                    </React.Fragment>
                ),
            },
            {
                field: "actions",
                type: "actions",
                width: 10,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={(e) => openEditorDialog(e, String(params.id))}
                        showInMenu
                    />,
                ],
            },
        ],
        [],
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setFilter({ tableId: "tasks", filter: filterValue }));
        }, 1000);

        return () => clearTimeout(timer);
    }, [filterValue, dispatch]);

    const queryString = React.useMemo(
        () => createQueryString(sort, filter),
        [sort, filter],
    );
    const { data, isFetching } = useGetTasksQuery(queryString);

    let selectedTask: TaskType;

    if (data && selectedTaskId) {
        const tasks: TaskType[] = data.content;
        selectedTask = tasks.find((element) => element.id === selectedTaskId);
    }

    return (
        <React.Fragment>
            {editorOpen && (
                <TaskEditorDialog
                    onClose={closeEditorDialog}
                    task={selectedTask}
                />
            )}
            <DataGrid
                filterModel={filterValue}
                sortModel={sort}
                rows={data?.content || []}
                columns={columns}
                pageSizeOptions={[data?.number || 0]}
                sortingMode="server"
                onSortModelChange={(params) => {
                    dispatch(setSort({ tableId: "tasks", sort: params }));
                }}
                filterMode="server"
                onFilterModelChange={setFilterValue}
                loading={isFetching}
                autoPageSize
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            createdAt: false,
                            id: false,
                        },
                    },
                }}
                slots={{
                    toolbar: CustomToolbar(openEditorDialog),
                }}
            />
        </React.Fragment>
    );
};

export default Tasks;
